import React, { useState } from "react";
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby";
import ScrollAnimation from 'react-animate-on-scroll';
import parse from 'html-react-parser';
import Slider from "react-slick";
import NoImage from "../../../images/cubitt-no-image.png"
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";
import "./NewsInsights.scss"

import { inViewOptions, contentItemStagger, contentItem, fadeInFromTop } from '../../utils/animation';

import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const settings = {
  dots: false,
  speed: 800,
  infinite: true,
  arrows: true,
  mobileFirst: true,
  centerMode: false,
  draggable:false, 
  responsive: [
    {
      breakpoint: 3020,
      settings: { 
        slidesToShow: 3,
        slidesToScroll: 1,

      }
    },
    {
      breakpoint: 1199,
      settings: { 
        slidesToShow: 3,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 991,
      settings: { 
        slidesToShow: 2
      }
    },
    {
      breakpoint: 767,
      settings: { 
        slidesToShow: 1
      }
    }
  ]
};
const NewsInsights = (props) => {


  const data = useStaticQuery(graphql`
  query {
    glstrapi {

      allNews(publicationState: LIVE, sort: "Date:desc", limit: 8) {
        id
        Title
        URL
        Tile_Image {
          url
          alternativeText
        }
        imagetransforms
      }

    }
  }
`);

  const allnews = data.glstrapi.allNews;


  return (
    <InView  {...inViewOptions}>
      {({ ref, inView }) => (
        <motion.section
          className="insights-row"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={contentItemStagger}

        >
          <Container>
            <Row>
              <Col className="d-flex align-item-center">
                <motion.div key={1} custom={1} variants={fadeInFromTop} className="insights-title">
                  <div className="sub-heading">
                    {props.branch_name !== "Sutton" && props.branch_name !== "Emsworth" && props.branch_name !== "Ashington" ? <span className="sub-title">{props.Latest_News_Heading}</span> : <h4 className="sub-title">News & insights</h4> }
                    <Link to="/latest-property-news/" className="link-text">
                      <span>More News</span> <i className="icon-arrow"></i>
                    </Link>
                  </div>
                  {parse(props.Latest_News_Intro_Content)}
                </motion.div>
              </Col>
            </Row>
            <div className="insights-slider  custom-slider">
              <Slider className="banner-imgslider d-block" {...settings}>

                {allnews.map((item, index) => {
                  const image_url = item.Tile_Image?.url;

                  let processedImages = JSON.stringify({});
                  if (item.imagetransforms?.Tile_Image_Transforms) {
                    processedImages = item.imagetransforms.Tile_Image_Transforms;
                  }
                  return (
                    <motion.div key={index} custom={index + 1} variants={fadeInFromTop} className="insight-item">
                      <div className="img-zoom">
                         <Link to={`/latest-property-news/${item.URL}`}>  
                          {item.Tile_Image ?
                            <ImageTransform
                              imagesources={image_url}
                              renderer="srcSet"
                              imagename="all-news.Tile_Image.tile_1"
                              attr={{ alt: '', class: '' }}
                              imagetransformresult={processedImages}
                              id={item.id}
                            /> :
                            <img src={NoImage} alt={item.Title} />}
                         </Link>  
                      </div>
                      <div className="insights-block">
                        <h3>
                          <Link to={`/latest-property-news/${item.URL}`}>{item.Title}</Link>
                        </h3>
                      </div>
                    </motion.div>
                  )
                })}

              </Slider>
            </div>
          </Container>
        </motion.section>
      )}
    </InView>
  )
}
export default NewsInsights
