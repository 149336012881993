import React from "react";
import Arundel from "../../images/branch-awards/BPA_Arundel_2023.png";
import Bognor from "../../images/branch-awards/BPA_Bognor_2023.png";
import Chichester from "../../images/branch-awards/BPA_Chichester.png";
import Cranleigh from "../../images/branch-awards/BPA_Cranleigh_2022.png";
import Crawley from "../../images/branch-awards/BPA_Crawley_2022.png";
import Crowborough from "../../images/branch-awards/BPA_Crowborough_2022.png";
import Emsworth from "../../images/branch-awards/BPA_Emsworth_2022.png";
import Fiveways from "../../images/branch-awards/BPA_Brighton_2022.png";
import Peacehaven from "../../images/branch-awards/BPA_Peacehaven_2023.png";
import Purley from "../../images/branch-awards/BPA_Purley.png";
import Redhill from "../../images/branch-awards/BPA_Redhill_2022.png";
import Saltdean from "../../images/branch-awards/BPA_Saltdean_2022.png";
import Waterlooville from "../../images/branch-awards/BPA_Waterlooville.png";

const branchAwardMap = {
    'arundel': Arundel,
    'bognor': Bognor,
    'chichester':Chichester,
    'cranleigh': Cranleigh,
    'crawley': Crawley,
    'crowborough': Crowborough,
    'emsworth': Emsworth,
    'fiveways': Fiveways,
    'peacehaven': Peacehaven,
    'purley': Purley,
    'redhill': Redhill,
    'saltdean': Saltdean,
    'waterlooville' :Waterlooville
};

const BranchAwardComponent = ( props ) => {
    const imageUrl = branchAwardMap[props.slug];
    if (!imageUrl) {
        // If the slug doesn't match any key in the mapping, return null or another fallback
        return null;
    }
    return(
       <div className="branch-award">
           <img src={imageUrl} alt="" />
        </div>
    );
};

export default BranchAwardComponent;